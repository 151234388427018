
import {computed, defineComponent, ref} from 'vue';
import {useStore} from "@/store";
import ActiveFilter from "@/components/FilterSort/ActiveFilter.vue";
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
	name: 'Filter',
	components: {DefaultIcon, ActiveFilter},
	props: {
		FilterCategoryItem: {
			type: String,
			default: 'Category'
		},
		FilterPriceItem: {
			type: String,
			default: 'Price'
		},
	},
	emits: [],
	setup() {
		const showDropdown = ref(false);

		function toggleDropdown() {
			showDropdown.value = !showDropdown.value;
		}

		function open() {
			showDropdown.value = true;
		}

		function close() {
			showDropdown.value = false;
		}

		const store = useStore()

		const fullName = computed(() => store.getters['user/fullName'])

		return {
			fullName, showDropdown, toggleDropdown, open, close
		}
	},
	methods: {}
})
