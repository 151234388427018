
import {defineComponent, ref} from 'vue';
import DefaultBoostpackCardLayout from "@/components/Card/DefaultBoostpackCardLayout.vue";
import {Boostpack} from "@/services/boostpack/boostpack.type";

export default defineComponent({
	name: 'DefaultBoostpackCard',
	components: {DefaultBoostpackCardLayout},
	props: {
		//sm:max-w-sm max-w-xs
		cardWidth: {
			type: String,
			default: "w-full",
		},
		mobileCardWidth: {
			type: String,
			default: "",
			// default: "sm:w-full",
		},
		centerCard: {
			type: Boolean,
			default: true,
		},
		boostpack: {
			type: Boostpack,
			default: null
		},
		index: {
			type: Number,
			default: null
		},
		adsKey: {
			type: String
		}
	},
	setup(props) {
		function subscribe() {
			// eslint-disable-next-line no-console
			console.log("SUBSCRIBE");
		}

		if (props.boostpack !== null) {
			const boostpackId = ref(props.boostpack.id);
			const boostpackCreator = ref(props.boostpack?.user.name);
			const boostpackReference = ref(props.boostpack.reference) ?? null;
			const boostpackTitle = ref(props.boostpack.title);
			const boostpackDescription = ref(props.boostpack.description);
			const boostpackItems = ref(props.boostpack.boostpackItems);
			const category = ref(props.boostpack.category);
			const categoryTitle = ref(props.boostpack.category.categoryName);

			return {subscribe, boostpackCreator, boostpackId, boostpackReference, boostpackTitle, boostpackDescription, boostpackItems, category, categoryTitle};
		} else {
			const boostpackId = null;
      const boostpackCreator = "";
      const boostpackReference = null;
			const boostpackTitle = "";
			const boostpackDescription = "";
			const boostpackItems = {};
			const category = null;
			const categoryTitle = "";

			return {subscribe, boostpackCreator, boostpackId, boostpackReference, boostpackTitle, boostpackDescription, boostpackItems, category, categoryTitle};
		}
	}
})
