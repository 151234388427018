
import {defineComponent, onMounted, ref} from 'vue';
import Switch, {TabInterface} from "@/components/FilterSort/Switch.vue";
import {useRoute} from "vue-router";
import useTranslation from "@/composable/translation/useTranslation";
import BoostpackItemFeed from "@/components/Boostpack/BoostpackItemFeed.vue";
import BoostpackFeed from "@/components/Boostpack/BoostpackFeed.vue";

export default defineComponent({
	name: 'Feed',
	components: {
		BoostpackFeed,
		BoostpackItemFeed,
		Switch,
	},
	props: {},
	setup() {
		const route = useRoute();
		const {t} = useTranslation();
		const feedTabs: Array<TabInterface> = [
			{name: t("boostpack.Feed.Items"), link: '/feed/items', current: false},
			{name: t("boostpack.Feed.Boostpacks"), link: '/feed/boostpacks', current: true},
		];
		const currentRoute = ref('');

		function triggerTab(tab: TabInterface) {
			currentRoute.value = tab.link;
		}

		onMounted(() => {
			currentRoute.value = route.path
		});

		return {feedTabs, triggerTab, currentRoute}
	},
})
