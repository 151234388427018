
import {defineComponent, onMounted, ref} from 'vue';
import useCustomStore, {HtmlAddPositionType} from "@/composable/custom/useCustomStore";
import {nanoid} from 'nanoid'

export default defineComponent({
	name: 'DefaultAd',
	components: {},
	props: {
		title: {
			type: String,
			default: "",
		},
		adClient: {
			type: String,
			default: "ca-pub-4888716858673136",
		},
		adSlot: {
			type: String,
			default: "3580154376",
		},
		adLayoutKey: {
			type: String,
			default: "-fb+5w+4e-db+86",
		},
		adFormat: {
			type: String,
			default: 'fluid',
		},
		adStyle: {
			type: String,
			default: '',
		},
		adResponsive: {
			type: String,
			default: 'false',
		},
	},
	setup(props) {
		const {isFullCookieConsentAllowed, createScriptElement, appendScriptElement} = useCustomStore();
		const adId = ref(nanoid() + (Math.floor(Math.random() * 10000) + 1));
		const titleText = ref(props.title);

		function isDevelopment() {
			return process.env.NODE_ENV != 'production';
		}

		function isProduction(): boolean {
			// return true;
			return process.env.NODE_ENV === 'production';
		}

		function isDevMode() {
			if (isProduction()) {
				return "off";
			} else {
				return "on";
			}
		}

		function appendAdScriptToAdd() {
			if (isFullCookieConsentAllowed()) {
				const inAdd = document.getElementById(adId.value);
				// const inAdd = document.querySelector('#'+adId.value);

				const adLoaded = (inAdd && inAdd.dataset.adloaded == 'true');
				if (inAdd && !adLoaded) {
					inAdd.dataset.adloaded = 'true';
					const adScriptBefore = {
						'src': 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4888716858673136',
						'arguments': {'async': true, 'crossorigin': 'anonymous'},
					};
					const scriptElementBeforeInsElement = createScriptElement(adScriptBefore.src, adScriptBefore.arguments);
					appendScriptElement(scriptElementBeforeInsElement, inAdd, HtmlAddPositionType.ADD_BEFORE, true);
					const adScriptAfter = {
						'content': '\n(adsbygoogle = window.adsbygoogle || []).push({});\n',
					};
					const scriptElementAfterInsElement = createScriptElement('', {}, adScriptAfter.content);
					appendScriptElement(scriptElementAfterInsElement, inAdd, HtmlAddPositionType.ADD_AFTER, true);
				}
			}
		}

		onMounted(() => {
			setTimeout(() => {
				appendAdScriptToAdd();
			}, 3000);
		});

		return {titleText, isDevMode, isDevelopment, isFullCookieConsentAllowed, adId};
	}
})
