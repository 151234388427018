
import {defineComponent, onMounted, onUnmounted, onBeforeUnmount, ref, watch, computed} from 'vue';
import {useRoute} from "vue-router";
import DefaultBoostpackCard from "@/components/Card/Boostpack/DefaultBoostpackCard.vue";
import Search from "@/components/Search/Search.vue";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import useBoostpackItemRepositories from "@/composable/boostpack/useBoostpackItemRepositories";
import {useStore} from "@/store";
import useCustomStore from "@/composable/custom/useCustomStore";
import DefaultSpinner from "@/components/Loading/DefaultSpinner.vue";
import Filter from "@/components/FilterSort/Filter.vue";
import DefaultAd from "@/components/Ads/DefaultAd.vue";
import {makeUseInfiniteScroll} from "@/composable/core/useLazyLoading";

export default defineComponent({
	name: 'BoostpackFeed',
	components: {
		// BoostpackCanvasEditor,
		DefaultAd,
		Filter,
		DefaultSpinner,
		Search,
		DefaultBoostpackCard
	},
	props: {},
	setup() {
		const {initBoostpacks, getBoostpacks, getForceReload, setForceReload} = useBoostpackItemRepositories();
		const {getPageReloaded, setPageReloaded, isMobileDevice} = useCustomStore();
		const pageReloaded = ref(getPageReloaded);
		const boostpackFeedLoading = ref(false);
		const isFetchingData = ref(false);
		const searchValue = ref("");
		const store = useStore()
		const searchValueFromStorage = computed(() => store.getters['boostpack/getSearchValue']);
		const route = useRoute();
		let lastAdIndex = 0;

		const useInfiniteScroll = makeUseInfiniteScroll({}) // the argument is optional
		const intersectionTrigger: any = ref(null) // as Ref<HTMLElement>
		const pageRef = useInfiniteScroll(intersectionTrigger)

		function startLoading() {
			boostpackFeedLoading.value = true;
		}

		function stopLoading() {
			boostpackFeedLoading.value = false;
			isFetchingData.value = false;
		}

		async function fetchItemsLazyLoading(page) {
			if (isFetchingData.value) {
				return;
			}
			let perPage: number|null = 4;
			if (!isMobileDevice()) {
				perPage = 9;
			}
			const boostpacksLength = getBoostpacks.value.length;
			if (page >= 1 && ((page * perPage) <= boostpacksLength) && !getForceReload.value) {
				stopLoading();
				return false;
			}
			let clearBoostpackList = false;
			if (pageReloaded.value || !boostpacksLength) {
				clearBoostpackList = true;
				setPageReloaded(false);
			}
			if (searchValueFromStorage.value) {
				searchValue.value = searchValueFromStorage.value;
			}
			BoostpackService.getBoostpackFeedBySearch(searchValue.value, page, perPage).then((boostpacks) => {
				isFetchingData.value = true;
				if ( (boostpacks && boostpacks.paginationMetaData !== null && boostpacks.paginationMetaData.to !== null && boostpacks.paginationMetaData.total !== boostpacksLength) || clearBoostpackList || getForceReload.value) {
					initBoostpacks(boostpacks, clearBoostpackList);
				}
				stopLoading();
			});
		}

		async function searchBoostpacksByStorageSearchValue() {
			BoostpackService.getBoostpackFeedBySearch(searchValue.value, "1").then((result) => {
				initBoostpacks(result, getForceReload.value);
				stopLoading();
			})
		}

		async function searchBoostpacks(e) {
			BoostpackService.getBoostpackFeedBySearch(searchValue.value, "1").then((result) => {
				initBoostpacks(result, true);
				stopLoading();
				setPageReloaded(false);
			})
			// initBoostpacks(await BoostpackService.getBoostpackFeedBySearch(searchValue.value, "1"), true);
			// stopLoading();
			// setPageReloaded(false);
		}

		onBeforeUnmount(() => {
			startLoading();
			setTimeout(function () {
				stopLoading();
			}, 3000);
		})

		onUnmounted(() => {
			stopLoading();
		})

		onMounted(() => {
			startLoading();

			let boostpackSearchValue = route.query.search;
			if (searchValueFromStorage.value && !boostpackSearchValue) {
				searchValue.value = searchValueFromStorage.value;
				searchBoostpacksByStorageSearchValue();
			} else if(boostpackSearchValue) {
				boostpackSearchValue = String(boostpackSearchValue);
				store.commit('boostpack/search', boostpackSearchValue);
				searchBoostpacksByStorageSearchValue();
			}

			setTimeout(() => {
				if (route.query.boostpack_reference) {
					const boostpackCard = document.getElementById(String(route.query.boostpack_reference))
					if (boostpackCard) {
						boostpackCard.scrollIntoView({behavior: "smooth"})
					}
				}
			}, 500);
			setTimeout(function () {
				stopLoading();
			}, 3000);
		})

		/**
		 *
		 */
		function isBoostpackFeedLoading(): boolean {
			return boostpackFeedLoading.value === true;
		}

		function getRandomInt(max) {
			return Math.floor(Math.random() * max);
		}

		function isAdEnabled(index): boolean {
			if (getBoostpacks.value && index) {
				const calcIndex = index +1;
				const boostpackLength = getBoostpacks.value.length;
				const minAdDistance = 1;
				const maxAdDistance = 4;
				const adDistance = Math.abs(calcIndex - lastAdIndex);
				const randomIndex = getRandomInt(calcIndex);
				const randomBoostpackLength = getRandomInt(boostpackLength);
				if ((randomIndex > randomBoostpackLength && adDistance > minAdDistance) || (adDistance > maxAdDistance)) {
					lastAdIndex = calcIndex;
					return true;
				} else {
					return false;
				}
			}
			return false;
		}

		window.addEventListener('beforeunload', function (event) {
			setPageReloaded(true);
			setForceReload(true);
		})

		watch(
			pageRef,
			(page) => {
				fetchItemsLazyLoading(page)
			},
			{ immediate: true }
		)

		return {
			getBoostpacks,
			searchBoostpacks,
			boostpackFeedLoading,
			isBoostpackFeedLoading,
			isAdEnabled,
			intersectionTrigger,
			searchValue
		}
	},
})
