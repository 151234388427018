import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  id: "menubarParent",
  class: "mb-0 md:text-lg #:justify-center text-center"
}
const _hoisted_2 = {
  id: "menubar",
  class: "m-0 p-0 pb-1 overflow-y-hidden overflow-x-auto list-none whitespace-nowrap"
}
const _hoisted_3 = {
  class: "w-auto p-0 ml-0",
  style: {"margin-left":"0","padding":"0","width":"auto"}
}
const _hoisted_4 = { class: "cursor-pointer bg-white hover:bg-bp hover:text-white dark:hover:text-gray-900 dark:bg-gray-700 rounded-full flex items-center shadow\n\t\tpx-4 py-3 bp-font-text dark:text-gray-400\n\t\t transition-transform transform font-bold" }
const _hoisted_5 = {
  class: "cursor-pointer bg-white hover:bg-bp hover:text-white dark:hover:text-gray-900 dark:bg-gray-700 rounded-full flex items-center shadow\n\t\tpx-4 py-3 bp-font-text dark:text-gray-400\n\t\t transition-transform transform font-bold",
  style: {"z-index":"1"}
}
const _hoisted_6 = { class: "flex" }
const _hoisted_7 = { class: "aMenu bg-white dark:bg-gray-700 text-gray-400 w-full" }
const _hoisted_8 = { class: "hover:bg-bp hover:text-white dark:hover:text-gray-900" }
const _hoisted_9 = { class: "cursor-pointer bg-white hover:bg-bp hover:text-white dark:hover:text-gray-900 dark:bg-gray-700 rounded-full flex items-center shadow\n\t\tpx-4 py-3 bp-font-text dark:text-gray-400\n\t\t transition-transform transform font-bold" }
const _hoisted_10 = {
  class: "z-10 cursor-pointer bg-white hover:bg-bp hover:text-white dark:hover:text-gray-900 dark:bg-gray-700 rounded-full flex items-center shadow\n\t\tpx-4 py-3 bp-font-text dark:text-gray-400\n\t\t transition-transform transform font-bold",
  style: {"z-index":"1"}
}
const _hoisted_11 = { class: "flex" }
const _hoisted_12 = { class: "aMenu bg-white dark:bg-gray-700 text-gray-900 dark:text-gray-400 w-full" }
const _hoisted_13 = { class: "hover:bg-bp hover:text-white dark:hover:text-gray-900" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_active_filter = _resolveComponent("active-filter")
  const _component_DefaultIcon = _resolveComponent("DefaultIcon")

  return (_openBlock(), _createBlock("div", _hoisted_1, [
    _createVNode("ul", _hoisted_2, [
      _createVNode("li", _hoisted_3, [
        _createVNode(_component_active_filter)
      ]),
      _createVNode("li", _hoisted_4, _toDisplayString(_ctx.$t("filter.Filter.FilterTrending")), 1),
      _createVNode("li", _hoisted_5, [
        _createVNode("div", _hoisted_6, [
          _createTextVNode(_toDisplayString(_ctx.$t("filter.Filter.FilterCategory")) + " ", 1),
          _createVNode(_component_DefaultIcon, {
            icon: "dropdown_icon",
            "icon-height": "h-5"
          })
        ]),
        _createVNode("ul", _hoisted_7, [
          _createVNode("li", _hoisted_8, _toDisplayString(_ctx.FilterCategoryItem), 1)
        ])
      ]),
      _createVNode("li", _hoisted_9, _toDisplayString(_ctx.$t("filter.Filter.FilterDate")), 1),
      _createVNode("li", _hoisted_10, [
        _createVNode("div", _hoisted_11, [
          _createTextVNode(_toDisplayString(_ctx.$t("filter.Filter.FilterPrice")) + " ", 1),
          _createVNode(_component_DefaultIcon, {
            icon: "dropdown_icon",
            "icon-height": "h-5"
          })
        ]),
        _createVNode("ul", _hoisted_12, [
          _createVNode("li", _hoisted_13, _toDisplayString(_ctx.FilterPriceItem), 1)
        ])
      ])
    ])
  ]))
}