
import {defineComponent, onMounted, onBeforeUnmount, onUnmounted, ref, watch} from 'vue'
import {useRoute} from "vue-router";
import Search from "@/components/Search/Search.vue";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";
import useCustomStore from "@/composable/custom/useCustomStore";
import DefaultSpinner from "@/components/Loading/DefaultSpinner.vue";
import Filter from "@/components/FilterSort/Filter.vue";
import DefaultImageLayout from "@/components/Card/DefaultImageLayout.vue";
import useBoostpackItemHelper from "@/composable/boostpack/useBoostpackItemHelper";
import DefaultAd from "@/components/Ads/DefaultAd.vue";
import {makeUseInfiniteScroll} from "@/composable/core/useLazyLoading";
import {PaginationMetaInterface} from "@/services/boostpack/core/pagination_meta.interface";
import search from "@/components/Search/Search.vue";

export default defineComponent({
	name: 'BoostpackItemFeed',
	components: {
		Search,
		DefaultAd,
		Filter,
		DefaultSpinner, DefaultImageLayout
	},
	props: {},
	setup() {
		const {getPageReloaded, setPageReloaded} = useCustomStore();
		const {getAltImageTextByTitleDescriptionAndCategory} = useBoostpackItemHelper();
		const pageReloaded = ref(getPageReloaded);
		const boostpackItems = ref<any>([]);
		const boostpackFeedLoading = ref(false);
		const boostpackFeedLoadingExecutionTime = ref(Date.now());
		const searchValue = ref("");
		let lastAdIndex = 0;

		const boostpackMetaData: PaginationMetaInterface | any = ref({});
		const useInfiniteScroll = makeUseInfiniteScroll({}) // the argument is optional
		const intersectionTrigger: any = ref(null) // as Ref<HTMLElement>
		const pageRef = useInfiniteScroll(intersectionTrigger);
		const lastPage = ref();

		async function loadBoostpackItems(page = null) {
			boostpackFeedLoadingExecutionTime.value = Date.now();
			lastPage.value = page;
			BoostpackService.getBoostpackItemFeedWithAdditionalInfo("null", page, searchValue.value).then( (bpListTmp: any) => {
				if (bpListTmp) {
					boostpackMetaData.value = bpListTmp["data"]["paginationInfo"];
					delete bpListTmp.data.paginationInfo;
					Object.entries(bpListTmp.data).forEach(([key, value], index) => {
						if (value instanceof Object) {
							boostpackItems.value.push(value);
						}
					});
				}
				boostpackFeedLoading.value = false;
			})
			// const bpListTmp: any = await BoostpackService.getBoostpackItemFeedWithAdditionalInfo("null", page, searchValue.value);
			// if (bpListTmp) {
			// 	console.log("MIDDLE")
			// 	boostpackMetaData.value = bpListTmp["data"]["paginationInfo"];
			// 	delete bpListTmp.data.paginationInfo;
			// 	Object.entries(bpListTmp.data).forEach(([key, value], index) => {
			// 		if (value instanceof Object) {
			// 			boostpackItems.value.push(value);
			// 		}
			// 	});
			// }

			boostpackFeedLoading.value = false;
		}

		async function searchBoostpackItems(e) {
			boostpackItems.value = [];
			searchValue.value = e.value;
			await loadBoostpackItems();
		}

		async function fetchItemsLazyLoading(page) {
			await loadBoostpackItems(page);
		}

		onBeforeUnmount(() => {
			boostpackFeedLoading.value = true;
		})

		onUnmounted(() => {
			boostpackFeedLoading.value = false;
		})

		//TODO check detail images count with api and update or delete images
		onMounted(() => {
			const boostpackSearchValue = useRoute().query.search;
			if (boostpackSearchValue) {
				// boostpackSearchValue = String(boostpackSearchValue);
				// store.commit('boostpack/search', boostpackSearchValue);
				loadBoostpackItems();
			} else {
				loadBoostpackItems();
				if (pageReloaded.value) {
					// getBoostpackItems();
					setPageReloaded(false);
					// } else if (!BoostpackItemList.value || !BoostpackItemList.value.length) {
					// 	getBoostpackItems();
				}
			}
		})

		/**
		 *
		 */
		function isBoostpackFeedLoading(): boolean {
			return boostpackFeedLoading.value === true;
		}

		function getRandomInt(max) {
			return Math.floor(Math.random() * max);
		}

		function isAdEnabled(index): boolean {
			if (boostpackItems.value && index) {
				const calcIndex = index + 1;
				const boostpackLength = boostpackItems.value.length;
				const minAdDistance = 2;
				const maxAdDistance = 9;
				const adDistance = Math.abs(calcIndex - lastAdIndex);
				const randomIndex = getRandomInt(calcIndex);
				const randomBoostpackLength = getRandomInt(boostpackLength);
				if ((randomIndex > randomBoostpackLength && adDistance > minAdDistance) || (adDistance > maxAdDistance)) {
					lastAdIndex = calcIndex;
					return true;
				} else {
					return false;
				}
			}
			return false;
		}

		window.addEventListener('beforeunload', function (event) {
			setPageReloaded(true);
		})

		async function checkIfNewContentIsLoading() {
			const dif = (Date.now() - boostpackFeedLoadingExecutionTime.value) / 1000;
			console.log(dif, pageRef.value)
			if (dif > 2) {
				console.log("ASDFSADFADFSADFS")
				pageRef.value++
			}
		}

		watch(
			pageRef,
			(page) => {
				boostpackFeedLoading.value = true;
				fetchItemsLazyLoading(page)
			},
			{immediate: true}
		)

		return {
			boostpackItems,
			searchValue,
			searchBoostpackItems,
			boostpackFeedLoading,
			isBoostpackFeedLoading,
			getAltImageTextByTitleDescriptionAndCategory,
			isAdEnabled,
			intersectionTrigger,
			boostpackMetaData,
			checkIfNewContentIsLoading
		}
	},
})
