
import {defineComponent, onMounted, ref, watch} from 'vue';
import {Boostpack} from "@/services/boostpack/boostpack.type";
import {BoostpackService} from "@/services/api/boostpack/boostpack.service";

export default defineComponent({
	name: 'DefaultImageLayout',
	components: {},
	props: {
		boostpackId: {
			type: Number,
			default: 0,
		},
		boostpackReference: {
			type: String,
			default: null,
		},
		boostpack: {
			type: Boostpack,
			default: null
		},
		//sm:max-w-sm max-w-xs (old default: w-full)
		cardWidth: {
			type: String,
			default: "",
		},
		mobileCardWidth: {
			type: String,
			default: "",
			// default: "sm:w-full",
		},
		centerCard: {
			type: Boolean,
			default: true,
		},
		detailImage: {
			type: String,
			default: '',
		},
		altImageText: {
			type: String,
			default: '',
		},
		item: {
			type: Object,
			default: null,
		},
		grade: {
			type: Number,
			default: 0,
		},
		maxStars: {
			type: Number,
			default: 1
		},
		showViewButton: {
			type: Boolean,
			default: true
		},
	},
	setup(props) {
		const boostpackItem = ref(props.item);
		const boostpackItemData = ref();
		const isLike = ref(props.item.alreadyLiked ?? false);
		const likeCount = ref(props.item.boostpackLikes);

		async function sendLikeRequest(like: boolean) {
			isLike.value = like;
			const itemId = boostpackItem.value.boostpackItemId ?? boostpackItem.value.id;
			BoostpackService.likeBoostpackItem({boostpackItemId: itemId, like: like})
				.then(response => {
					isLike.value = like;
					likeCount.value = response.data.data.likeCount ?? 0;
					//TODO show cool animation heart beat...?
				},error => {
					isLike.value = !like;
				});
		}

		async function like() {
			await sendLikeRequest(true);
		}

		async function unLike() {
			await sendLikeRequest(false);
		}

		function loadBoostpackItemDataByData(itemData) {
			if (itemData.data && itemData.data.title) {
				boostpackItemData.value = itemData.data;
			} else if (itemData.data) {
				boostpackItemData.value = JSON.parse(itemData.data);
			}
			if (boostpackItemData.value && !boostpackItemData.value.publication) {
				boostpackItemData.value.publication = "";
			}
		}

		function getBoostpackDetailReference(): string | number {
			if (props.boostpackReference !== null && props.boostpackReference?.length > 0) {
				return props.boostpackReference;
			} else {
				return props.boostpackId
			}
		}

		onMounted(() => {
			loadBoostpackItemDataByData(boostpackItem.value);
		})

		watch(boostpackItem, value => {
			if (value) {
				loadBoostpackItemDataByData(value);
			}
		});

		if (props.boostpack !== null) {
			const boostpackId = ref(props.boostpack.id);
			const boostpackReference = ref(props.boostpack.reference) ?? null;
			return {boostpackId, boostpackReference};
		}

		return {isLike, like, unLike, likeCount, boostpackItem, boostpackItemData, getBoostpackDetailReference};
	}
})
