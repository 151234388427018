import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "rounded-full flex shadow\n\t\tactive-filter-bg px-4 py-3 text-white dark:text-gray-700 font-bold" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DefaultIcon = _resolveComponent("DefaultIcon")

  return (_openBlock(), _createBlock("button", _hoisted_1, [
    _createTextVNode(_toDisplayString(_ctx.ActiveFilter) + " ", 1),
    _createVNode(_component_DefaultIcon, {
      "icon-height": "h-6 md:h-7",
      "icon-width": "w-6 md:w-7",
      onClick: _ctx.close,
      icon: "menu_close_icon"
    }, null, 8, ["onClick"])
  ]))
}