import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveDynamicComponent as _resolveDynamicComponent, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock("div", null, [
    _createVNode("nav", {
      "aria-label": "Tabs",
      class: _ctx.wrapper
    }, [
      (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.currentTabs, (tab) => {
        return (_openBlock(), _createBlock("a", {
          key: tab.name,
          "aria-current": tab.current ? 'page' : undefined,
          class: tab.current ? _ctx.styleInactive : _ctx.styleActive,
          style: _ctx.customStyle,
          onClick: ($event: any) => (_ctx.triggerTab(tab))
        }, [
          _createVNode("span", _hoisted_1, [
            (_openBlock(), _createBlock(_resolveDynamicComponent(tab.icon), {
              class: [tab.current ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500', '-ml-0.5 mr-2 h-5 w-5'],
              "aria-hidden": "true"
            }, null, 8, ["class"])),
            _createTextVNode(" " + _toDisplayString(tab.name), 1)
          ])
        ], 14, ["aria-current", "onClick"]))
      }), 128))
    ], 2)
  ]))
}