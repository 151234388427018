
import {defineComponent} from 'vue';
import DefaultIcon from "@/components/Icon/DefaultIcon.vue";

export default defineComponent({
  name: 'ActiveFilter',
  components: {DefaultIcon},
  props: {
    ActiveFilter: {
      type: String,
      default: 'ActiveFilterItem'
    }
  },
  emits: [],
})
